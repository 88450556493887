import Flickity from 'flickity/dist/flickity.pkgd';
import AOS from 'aos';
import Typed from 'typed.js';
import {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
  } from 'bootstrap';

const howToTexteId = "section-how_to-text";
var currentHowToTopicId = 'how_to-topic-groups-1';
var currentHowToTopicIconId = 'how_to-topic-groups-icon-1';

function changeHowToText(text){
    document.getElementById(howToTexteId).innerHTML = text;
}

function onSubmitContact(){
    document.getElementById('contact-spinner').style.display = "";
    document.getElementById('contact-button').style.display = "none";
}

function onHowToTopicClick ({text='', element='', iconId=''}){
    changeHowToText(text);
    if(document.getElementById(currentHowToTopicId)){
        document.getElementById(currentHowToTopicId).classList.remove('selected');
        document.getElementById(currentHowToTopicIconId).classList.remove('selected-icon');
    }
    currentHowToTopicId = element;
    currentHowToTopicIconId = iconId;
    document.getElementById(element).className += ' selected';
    document.getElementById(currentHowToTopicIconId).className += ' selected-icon';
}

function searchTerm() {
    var input, filter, ul, li, title, innerTxt, i, titleValue, txtValue;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase().trimStart();
    ul = document.getElementById("questions");
    li = ul.getElementsByClassName("accordion-item");
    for (i = 0; i < li.length; i++) {
        title = li[i].getElementsByTagName("p")[0];
        innerTxt = li[i].getElementsByTagName("p")[1];
        titleValue = title.textContent || title.innerText;
        txtValue =  innerTxt.textContent || innerTxt.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1 || titleValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}

function init(){
    window.onHowToTopicClick = onHowToTopicClick;
    window.onSubmitContact = onSubmitContact;
    window.searchTerm = searchTerm;
    window.Alert = Alert;
    window.Button = Button;
    window.Carousel = Carousel;
    window.Collapse = Collapse;
    window.Dropdown = Dropdown;
    window.Modal = Modal;
    window.Offcanvas = Offcanvas;
    window.Popover = Popover;
    window.ScrollSpy = ScrollSpy;
    window.Tab = Tab;
    window.Toast = Toast;
    window.Tooltip = Tooltip;
  
    // INIT AOS
    const options = {
        duration: 700,
        easing: 'ease-out-quad',
        once: true,
        startEvent: 'load',
    };
    AOS.init(options);
    // Make available globally
    window.AOS = AOS;

    // INIT FLICKITY
    // Make available globally
    window.Flickity = Flickity;

    // INIT TYPED
    const toggles = document.querySelectorAll('[data-typed]');

    toggles.forEach((toggle) => {
    const elementOptions = toggle.dataset.typed ? JSON.parse(toggle.dataset.typed) : {};

    const defaultOptions = {
        typeSpeed: 40,
        backSpeed: 40,
        backDelay: 1000,
        loop: true,
    };

    const options = {
        ...defaultOptions,
        ...elementOptions,
    };

    new Typed(toggle, options);
    });

    // Make available globally
    window.Typed = Typed;
}
document.onreadystatechange = () => {
    const element = document.getElementById('landkit-body');
    
    if (document.readyState == "complete") {
        init();
        console.log('Loaded Vue');
    }

};

